import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import cardStyle from "../../assets/jss/material-dashboard-react/components/cardStyle.jsx";

const StyledCard = styled('div')(cardStyle.card);

function Card({ classes, className, children, plain, profile, chart, ...rest }) {
  const cardClasses = classNames({
    [classes?.card]: true, // Access classes safely
    [classes?.cardPlain]: plain,
    [classes?.cardProfile]: profile,
    [classes?.cardChart]: chart,
    [className]: className !== undefined,
  });

  return (
    <StyledCard className={cardClasses} {...rest}>
      {children}
    </StyledCard>
  );
}

Card.propTypes = {
  classes: PropTypes.object, // Make classes prop optional
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  chart: PropTypes.bool,
};

export default Card;