import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Card, Button, Paper, TextField, ButtonBase, Snackbar, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Link } from 'react-router-dom';
import apigClientFactory from './chalice-javascript-sdk/apigClient';
import background from './assets/img/db-bg.jpg';
import { isMobile } from "react-device-detect";
import CloseIcon from '@mui/icons-material/Close';

import LoginFooter from './components/Footer/LoginFooter';
import OMNIInfo from './components/Global/OMNIInfo';
import * as config from './Config/config';
import { checkCookie } from './utility/checkCookie';
import { secureStorage_getItem } from './utility/secureStorage';
import { dropCookie } from './utility/dropCookie';

const StyledContainer = styled('div')({
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    minHeight: '100vh'
});

class ConfirmUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmcode: '',
            open: checkCookie('vlomni-cookie', 'true') ? false : true,
            open1: false,
            errmsg: '',
            password: '',
            username: '',
            openA: false,
        };

        this.endPoints = config.createConfig().isProd ? config.prodEndPoints : config.devEndPoints;

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClickA = this.handleClickA.bind(this);
        this.handleCloseA = this.handleCloseA.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleCloseOpen = this.handleCloseOpen.bind(this);
        this.confirmUser = this.confirmUser.bind(this);
        this.resendConfirmCode = this.resendConfirmCode.bind(this);
        this.signIn = this.signIn.bind(this);
    }

    componentDidMount() {
        if ('email' in this.props.location) {
            this.setState({ username: this.props.location.email ? this.props.location.email : '' });
        }
        console.log('this.props.location before: ', this.props.location);
        if (!this.props.location.region) {
            console.log('no region in location. setting to us-east-1');
            this.props.location.region = "us-east-1";
        }
        console.log('this.props.location after: ', this.props.location);
    }

    async confirmUser() {
        const { username, confirmcode } = this.state;

        try {
            const apigClient = apigClientFactory.newClient(undefined, this.endPoints[this.props.location.region].apiurl);

            const body = {
                username: username,
                ConfirmationCode: confirmcode,
            };

            const result = await apigClient.vlauthConfirmUserPut('', body, '');
            this.setState({ open1: true, errmsg: "Successfully confirmed!" });
            this.props.history.push({
                pathname: '/login',
                email: this.state.username,
                from: this.props.location
            });
        } catch (error) {
            this.setState({ open1: true, errmsg: "An error has occurred. Please ensure your email and code are correct." || "Wrong confirmation code, please make sure you have the correct confirmation code!" });
        }
    }

    async resendConfirmCode() {
        const { username } = this.state;
        console.log('username: ', username);
        console.log('this.endPoints: ', this.endPoints)
        console.log(this.props.location.region)
        try {
            const apigClient = apigClientFactory.newClient(undefined, this.endPoints[this.props.location.region].apiurl);

            const body = {
                username: username,
            };

            await apigClient.vlauthResendConfirmationCodePut('', body, '');
            this.setState({ open1: true, errmsg: "Another Confirmation code has been sent to your email!" });
        } catch (error) {
            console.error('Error resending confirmation code:', error);
            this.setState({ open1: true, errmsg: "Failure to send code. Please check your credentials, or contact us at support@ehousestudio.com." || "Couldn't send the code! Please check to make sure your email is entered correctly!" });
        }
    }

    async signIn() {
        const { username, password } = this.state;

        try {
            const apigClient = apigClientFactory.newClient(undefined, this.endPoints[this.props.location.region].apiurl);

            const params = {
                username: username,
                password: password,
            };

            const result = await apigClient.vlauthSigninUsernamePasswordPut(params, '', '');
            localStorage.setItem("accesstoken", result.data.IdToken);
            localStorage.setItem("updatetoken", result.data.AccessToken);
            localStorage.setItem("username", username);
            localStorage.setItem("customername", result.data.customername);

            const paramsTmp = {
                tablename: "WebServiceCustomer",
                customername: secureStorage_getItem("customername"),
            };
            const additionalParamsTmp = {
                queryParams: {
                    fields: "StoreAdminURL"
                },
                headers: {
                    "Authorization": localStorage.getItem("accesstoken")
                }
            };

            const resultTmp = await apigClient.dbopsGetLatestRecordsTablenameCustomernameGet(paramsTmp, '', additionalParamsTmp);
            if (resultTmp.data.length > 0) {
                localStorage.setItem("storeadminurl", resultTmp.data[0].StoreAdminURL || "null");
            }
            window.location = "/gorgiasUser";
        } catch (error) {
            this.setState({ open1: true, errmsg: "Wrong username or password!" });
        }
    }

    handleUsernameChange(event) {
        this.setState({ username: event.target.value });
    }

    handleCodeChange(event) {
        this.setState({ confirmcode: event.target.value });
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.confirmUser();
        }
    }

    handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        dropCookie('vlomni-cookie', 'true', 2592000);
        this.setState({ open: false });
    }

    handleClickA() {
        this.setState({ openA: true });
    }

    handleCloseA(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ openA: false });
    }

    handleClickOpen() {
        this.setState({ open1: true });
    }

    handleCloseOpen() {
        this.setState({ open1: false });
    }

    render() {
        const { errmsg, username, confirmcode } = this.state;
        document.title = "VL Dashboard Confirm Login";

        const form = (
            <React.Fragment>
                <h2 style={{ borderRadius: 5, color: "black", margin: "auto", marginBottom: -25, textAlign: "center", fontSize: "1.8em", fontWeight: 400 }}>VL Dashboard - Confirm Login</h2>
                <form>
                    <TextField
                        id="email"
                        label="Email"
                        value={username}
                        onChange={this.handleUsernameChange}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: '5vh', width: "100%", marginLeft: 'auto', marginRight: 'auto', float: 'left', fontSize: '0.4em' }}
                    />
                    <TextField
                        id="confirmcode"
                        label="Confirmation Code"
                        value={confirmcode}
                        onChange={this.handleCodeChange}
                        onKeyPress={this.handleKeyPress}
                        style={{ marginTop: '0.5vh', width: "100%", marginLeft: 'auto', marginRight: 'auto', float: 'left' }}
                    />
                    <Button onClick={this.confirmUser} variant="contained" style={{ backgroundColor: "#161619", color: "white", margin: 'auto', width: "100%", fontSize: 20, fontFamily: "Lato", boxShadow: 'none', marginTop: '1.5vh' }}>
                        Confirm
                    </Button>
                </form>
                <div style={{ textAlign: "center", marginTop: "3%", fontSize: 13 }}>
                    <div>Confirmation code not working? <span style={{ color: "#00aae7", textDecoration: "underline", cursor: 'pointer' }} onClick={this.resendConfirmCode} color="primary"> Click here to resend. </span> </div>
                    <div>Having more trouble? Click here to sign up <Link to="/newUser" style={{ color: "#00aae7", textDecoration: "underline" }}>Click here</Link> or contact support@ehousestudio.com</div>
                </div>
            </React.Fragment>
        );

        const popup = (
            <React.Fragment>
                <Card style={{ position: "absolute", width: "100%", height: "100%", top: "0%", backgroundColor: "rgb(17, 20, 27)" }}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.open}
                        autoHideDuration={999999}
                        onClose={this.handleClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">The Site uses cookies. By using this website, you agree to our use of cookies.</span>}
                        action={[
                            <ButtonBase key='close' onClick={this.handleClose} style={{ color: "black", backgroundColor: "#ffd42d", padding: 5, borderRadius: 5 }}>
                                I Understand
                            </ButtonBase>,
                        ]}
                    />
                    <Snackbar style={{ marginTop: "50%" }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.openA}
                        onClose={this.handleCloseA}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Please make sure your username is entered. An email has been sent to your account with a verification code.</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.handleCloseA}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                    <img style={{ height: "100%", width: "100%", backgroundColor: "#161619" }} src={background} alt="background"></img>
                </Card>
            </React.Fragment>
        );

        const message = (
            <React.Fragment>
                <Dialog style={{ fontFamily: "Lato" }}
                    open={this.state.open1}
                    onClose={this.handleCloseOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{ boxSizing: 'border-box', background: "linear-gradient(60deg, #52607c, #96b0cb)", textAlign: "center", marginTop: -5, paddingTop: 20, paddingBottom: 15 }} id="alert-dialog-title"><span style={{ color: 'white', fontSize: 26 }}>{"Alert!"}</span></DialogTitle>
                    <DialogContent style={{ marginTop: 10 }}>
                        <DialogContentText id="alert-dialog-description">
                            {errmsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseOpen} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );

        if (isMobile) {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: "100%", height: "100%" }}>
                        {popup}
                        <Card style={{ position: "absolute", marginLeft: "5%", marginRight: "auto", width: "85%", height: "62%", top: "17%", backgroundColor: "white", verticalAlign: "middle", paddingTop: "5vh", paddingRight: "3%", paddingLeft: "3%", textAlign: "left", borderRadius: 20 }}>
                            {form}
                        </Card>
                        {message}
                        <LoginFooter />
                    </Paper>
                </div>
            );
        } else {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: "100%", height: "100%" }}>
                        {popup}
                        <OMNIInfo />
                        <Card style={{ position: "absolute", marginLeft: "45%", marginRight: "auto", width: "32%", height: "60%", top: "13%", backgroundColor: "white", verticalAlign: "middle", paddingTop: "5vh", paddingRight: "3%", paddingLeft: "3%", textAlign: "left", borderRadius: 20 }}>
                            {form}
                        </Card>
                        {message}
                        <LoginFooter />
                    </Paper>
                </div>
            );
        }
    }
}

ConfirmUser.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default ConfirmUser;