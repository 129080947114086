//Created on July 9th, 2021
//Updated on January 19th, 2023
//Author: Vaibhavi More
import React from 'react';
import { styled } from '@mui/system';
import { isMobile } from "react-device-detect";
import Typography from '@mui/material/Typography';
import { Button, Paper, Backdrop } from '@mui/material';
// import background from '../../src/loginBackgroundBlueBase.png';
import background from '../assets/img/db-bg.jpg';
import amzn_sellercentral_logo from './amazonGenericTransparent.png';
import amzn_vendorcentral_logo from './amazonGenericTransparent.png';
import { secureStorage_getItem, secureStorage_setItem } from '../utility/secureStorage';
import * as config from '../Config/config';
import { resetStorage } from '../utility/resetStorage';
import apigClientFactory from '../chalice-javascript-sdk/apigClient.js';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    paper: {
        padding: '16px',
        textAlign: 'center',
    },
});

//Amazon Seller Central
class Step1 extends React.Component {

    componentDidMount() {
        console.log('Component did mount');
        console.log(secureStorage_getItem("customername"));
        this.handleFetchClick();
        console.log('with correct api without beta version');
    }

    fetchCustomerID = async (sellerid) => {
        console.log('Fetching customer ID for sellerid:', sellerid);
        fetch(`https://f4q7s67aunnfm7zv3bepcutd7m0rmhcd.lambda-url.us-east-1.on.aws/sellerid/${sellerid}`).then((response) => response.json()).then((responseData) => {
            console.log('Fetched CustomerID:', responseData.data[0].CustomerID);
            secureStorage_setItem('customeridfetched', responseData.data[0].CustomerID);
        }).catch((error) => {
            console.error('Error fetching customer ID:', error);
            secureStorage_setItem('customeridfetched', '');
        });
    }

    handleLogout = () => {
        console.log('Handling logout');
        resetStorage();

        this.props.history.push({
            pathname: '/login'
        });
    }

    handleFetchClick = async () => {
        console.log('Handling fetch click');
        const tempSellerId = secureStorage_getItem('selling_partner_id');
        console.log('Temporary Seller ID:', tempSellerId);
        this.fetchCustomerID(`${tempSellerId}`);
    }

    handleClick = async () => {
        console.log('Handling click');
        var customername = secureStorage_getItem("customername");
        console.log('Customer name:', customername);

        //determine vendor/seller account
        //default region and url
        var tempRegion = 'US';
        var url = config.sellerCentralURL[tempRegion];
        localStorage.setItem("region", tempRegion);
        localStorage.setItem("url", url);
        console.log('Default region and URL set:', tempRegion, url);

        let regions = config.marketplaceRegion;
        Object.keys(regions).forEach(region => {
            if (regions[region].includes(customername)) {
                tempRegion = region;
                url = config.sellerCentralURL[region];
                localStorage.setItem("url", url);
                localStorage.setItem("region", region);
                console.log('Region and URL updated:', region, url);
            }
        });

        if (this.props.location["pathname"].includes('amazon-vendor-central')) {
            if (this.props.location["search"].includes('Canada')) {
                localStorage.setItem("region", 'Canada');
                url = 'https://vendorcentral.amazon.ca';
                localStorage.setItem("url", url);
                console.log('Vendor Central Canada URL set:', url);
            } else {
                url = config.vendorCentralURL[tempRegion];
                localStorage.setItem("url", url);
                console.log('Vendor Central URL set:', url);
            }
        }

        console.log('Final URL:', url);
        var app_id = config.amazon_app_id;
        var apigClient = apigClientFactory.newClient();
        var accesstoken = localStorage.getItem("accesstoken");
        console.log('Access token:', accesstoken);

        var tempURL = url + "/apps/authorize/consent?application_id=" + app_id + "&state=" + accesstoken + "&version=beta";
        console.log('Redirect URL:', tempURL);
        window.location = tempURL;

        var body = {
            customerid: secureStorage_getItem("customername"),
            info: "Clicked Authorize me button, navigate to" + tempURL
        };

        var additionalParams = {
            headers: {
                "Authorization": accesstoken
            }
        };

        apigClient.awsopsGenerateLogPut('', body, additionalParams).then(result => {
            console.log('Log put result:', result);
        }).catch(function (error) {
            console.error('Error in awsopsGenerateLogPut:', error);
        });

    }

    handlePopupClose = () => {
        console.log('Handling popup close');
        this.handleLogout();
    }

    render() {
        console.log('Rendering component');
        document.title = "eHouse Studio";

        var amzn_logo = <img src={amzn_sellercentral_logo} style={{ maxWidth: '200px', maxHeight: '100%', marginLeft: 3 + '%', marginRight: "auto" }} alt="Amazon Seller Central" />;
        var disc1 = <Typography variant="body1" style={{ margin: 2 + '%', marginBottom: 2 + '%', color: "#6d6e70", marginTop: 100 + "px", fontWeight: "bold" }}>Disclaimer: By clicking this button you authorize eHouse Studio to access your Seller Central data over REST APIs.</Typography>;
        var disc2 = <Typography variant="body1" style={{ margin: 2 + '%', marginBottom: 5 + '%', color: "#6d6e70" }}>We will redirect you to Amazon selling APIs consent page where you can grant permissions to eHouse Studio app to access your seller central data over REST APIs.</Typography>;

        if (this.props.location["pathname"].includes('vlomni-authorize-amazon-vendor-central')) {
            console.log('Vendor Central authorization path detected');
            amzn_logo = <img src={amzn_vendorcentral_logo} style={{ maxWidth: '200px', maxHeight: '100%', marginLeft: 3 + '%', marginRight: "auto" }} alt="Amazon Seller Central" />;
            disc1 = <Typography variant="body1" style={{ margin: 2 + '%', marginBottom: 2 + '%', color: "#6d6e70", marginTop: 100 + "px", fontWeight: "bold" }}>Disclaimer: By clicking this button you authorize eHouse Studio to access your Vendor Central data over REST APIs.</Typography>;
            disc2 = <Typography variant="body1" style={{ margin: 2 + '%', marginBottom: 5 + '%', color: "#6d6e70" }}>We will redirect you to Amazon selling APIs consent page where you can grant permissions to eHouse Studio app to access your vendor central data over REST APIs.</Typography>;
        }

        var content = (
            <div style={{ position: "absolute", top: 30 + "%", left: 50 + '%', marginTop: 5 + '%', verticalAlign: "middle", transform: "translate(-50%, -50%)" }}>
                {/* <img src={config.defaultEHouseLogo} style={{ maxWidth: '63px', maxHeight: '100%', marginTop: 15 + '%', marginRight: "auto" }} alt="eHouse Studio" />                 */}
                {amzn_logo}

                <Typography variant="h3" style={{ color: "#4E2DD2", fontSize: 30 + "px", marginLeft: 2 + "%", marginRight: "auto", marginTop: 80 + "px", textAlign: "center" }}>Welcome to eHouse Studio</Typography>
                {disc1}
                {disc2}

                <Button variant="contained"
                    style={{ backgroundColor: "#4E2DD2", color: "white", fontWeight: "bold", fontFamily: "Lato", marginTop: 10 + 'px', float: "right" }}
                    onClick={this.handleClick}>
                    Authorize Me
                </Button>
            </div>
        );

        var dialog = (
            <div className="time-out-message" style={{ fontFamily: "Lato", display: 'none' }}>
                <Backdrop style={{ zIndex: "10000", backgroundColor: "rgba(0,0,0,0.5)" }} open={true}>
                </Backdrop>
                <div style={{ position: "fixed", zIndex: 10001, top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: 'white', borderRadius: '0.5em', padding: '2em' }} >
                    <span id="message-id">Your session has timed out.<br /> <span style={{ textDecoration: "underline", cursor: "pointer", color: "dodgerblue" }} onClick={this.handlePopupClose.bind(this)}>Click here</span> to log in again.</span>
                </div>
            </div>
        );

        if (isMobile) {
            console.log('Rendering mobile view');
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        {content}
                        <img style={{ width: 100 + "%", height: 100 + '%', backgroundColor: "#00a9e7" }} src={background} alt="Background"></img>
                    </Paper>
                    {dialog}
                </div>
            );
        } else {
            console.log('Rendering desktop view');
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        {content}
                        <img style={{ width: 100 + "%", backgroundColor: "#00a9e7" }} src={background} alt="Background"></img>
                        {dialog}
                    </Paper>
                </div>
            );
        }
    }
}

export default styled(Step1)(styles);
