import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/system components
import { styled } from "@mui/system";
// core components
import cardBodyStyle from "../../assets/jss/material-dashboard-react/components/cardBodyStyle.jsx";

const CardBody = styled('div')(cardBodyStyle.cardBody);

function CardBodyComponent({ ...props }) {
  const { className, children, plain, profile, ...rest } = props;
  const cardBodyClasses = classNames({
    cardBody: true,
    cardBodyPlain: plain,
    cardBodyProfile: profile,
    [className]: className !== undefined
  });
  return (
    <CardBody className={cardBodyClasses} {...rest}>
      {children}
    </CardBody>
  );
}

CardBodyComponent.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool
};

export default CardBodyComponent;