export const loadHubSpot = async () => {
    return new Promise((resolve, reject) => {
        try {
            // Remove any existing HubSpot scripts and elements
            removeHubSpot();

            // Create and add new script
            const script = document.createElement('script');
            script.id = 'hs-script-loader';
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.src = '//js.hs-scripts.com/1697964.js';

            // Add charset
            script.charset = 'UTF-8';

            // Add crossorigin
            script.crossOrigin = 'anonymous';

            // Add event listeners
            script.addEventListener('load', () => {
                console.log('HubSpot script loaded successfully');
                resolve('HubSpot loaded successfully');
            });

            script.addEventListener('error', (error) => {
                console.error('Failed to load HubSpot script:', error);
                reject(new Error('Failed to load HubSpot'));
            });

            // Inject the script
            document.head.appendChild(script);

            // Set a timeout in case the script takes too long
            setTimeout(() => {
                if (!window.HubSpotConversations) {
                    console.warn('HubSpot failed to initialize after timeout');
                    reject(new Error('HubSpot initialization timeout'));
                }
            }, 10000);

        } catch (error) {
            console.error('Error in loadHubSpot:', error);
            reject(error);
        }
    });
};

export const removeHubSpot = () => {
    try {
        // Remove existing script
        const script = document.getElementById('hs-script-loader');
        if (script) {
            script.remove();
        }

        // Remove all HubSpot elements
        const elements = document.querySelectorAll('[data-hs-portal], [class*="hs-"], [id*="hs-"], [src*="hubspot"]');
        elements.forEach(element => element.remove());

        // Clean up HubSpot cookies
        document.cookie.split(';').forEach(cookie => {
            const name = cookie.split('=')[0].trim();
            if (name.includes('hs') || name.includes('hubspot')) {
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname}`;
            }
        });

        // Clean up HubSpot global variables
        if (window.HubSpotConversations) {
            delete window.HubSpotConversations;
        }
        if (window.hsConversationsSettings) {
            delete window.hsConversationsSettings;
        }

        return true;
    } catch (error) {
        console.error('Error removing HubSpot:', error);
        return false;
    }
};

// Keep the alias for backward compatibility
export const unloadHubSpot = removeHubSpot;