import React, { Component, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import ForgotPassword from './ForgotPassword';
//import MutationObserver from 'react-mutation-observer';
import HttpsRedirect from 'react-https-redirect';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/material/styles';

import { CircularProgress } from '@mui/material'
import './App.css';
import LoginPage from './LoginPage';
import ASCAuthS1 from './ASCAuth/Step1';
import ASCAuthS2 from './ASCAuth/Step2';
import { returnAccess } from './utility/returnAccess'
import NewUser from './NewUser';
import ConfirmUser from './ConfirmUser';
import { returnCookieValue } from './utility/returnCookieValue';
import { dropCookie } from './utility/dropCookie';
import * as config from './Config/config';
import { resetStorage } from './utility/resetStorage';
import CookieDrop from './components/CookieDrop/CookieDrop'
import ErrorBoundary from './hoc/ErrorBoundary/ErrorBoundary'
import ErrorPage from './components/PlaceHolder/Error';
import { secureStorage_getItem } from './utility/secureStorage';
import { loadHubSpot, removeHubSpot } from './utils/hubspot';

const NavWrapper = React.lazy(() => import('./NavWrapper'))
const NDNavWrapper = React.lazy(() => import('./Dashboardv3.0/newComponents/NavWrapper'))

const drawerWidth = 240;

const StyledContainer = styled('div')({
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    minHeight: '100vh'
});

class App extends Component {
    once(fn, context) {
        var result;
        return function () {
            if (fn) {
                result = fn.apply(context || this, arguments);
                fn = null;
            }
            return result;
        }
    }

    check = () => {
        let now = new Date()
        let last_active_time = returnCookieValue('vlomni-cookie-active');
        // last_active_time = new Date(Date.parse(last_active_time))
        let result = true

        if (returnCookieValue('vlomni-cookie-active') && (now.getTime() - last_active_time) / 1000 < config.SESSION_EXPIRESIN) {
            dropCookie('vlomni-cookie-active', now.getTime(), config.SESSION_EXPIRESIN)
        } else {
            resetStorage();
            if (!['/login', '/forgotPassword', '/newUser', '/confirmUser', '/vlomni/cookie/drop'].includes(this.props.location.pathname)) {
                result = false
            }
        }
        return result
    }

    init = this.once(function () {
        // this.check()
    })

    constructor(props) {
        super(props);
        this.init();
        console.log('version_update - aug-21-2024');
    }

    componentDidMount() {
        // Check if user is logged in and HubSpot should be loaded
        const shouldLoadHubSpot = localStorage.getItem('hubspot_enabled') === 'true';

        if (shouldLoadHubSpot) {
            loadHubSpot();
        }

        // Add history listener
        this.unlisten = this.props.history.listen((location, action) => {
            if (returnCookieValue("vlomni-cookie-active") !== null) {
                dropCookie("vlomni-cookie-active", new Date().getTime(), config.SESSION_EXPIRESIN)
            }
        });
    }

    componentWillUnmount() {
        // Clean up HubSpot
        removeHubSpot();

        // Remove history listener
        if (this.unlisten) {
            this.unlisten();
        }
    }

    render() {
        var db = secureStorage_getItem('database')
        var isSessionActive = this.check()
        var access = returnAccess()

        return (
            <HttpsRedirect>
                <ErrorBoundary>
                    <Route path='/'>
                        <Suspense fallback={<div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div>}>
                            <StyledContainer>
                                <Switch>
                                    <Route exact path="/error" component={ErrorPage} />
                                    <Route path='/vlomni/cookie/drop' component={CookieDrop} />
                                    <Route path='/login' component={LoginPage} exact />
                                    <Route path='/forgotPassword' component={ForgotPassword} exact />
                                    <Route path='/newUser' component={NewUser} exact />
                                    <Route path='/vlomni-authorize-amazon-seller-central' component={props => { return isSessionActive ? <ASCAuthS1 {...props} /> : <LoginPage {...props} /> }} />
                                    <Route path='/vlomni-authorize-amazon-vendor-central' component={props => { return isSessionActive ? <ASCAuthS1 {...props} /> : <LoginPage {...props} /> }} />
                                    <Route path='/vlomni-amazon-api-authorize' component={ASCAuthS2} exact />
                                    <Route path='/confirmUser' component={ConfirmUser} exact />
                                    {
                                        db === 'rds'
                                            ? <Route key='rdsRoute' path='/' component={props => { return isSessionActive ? <NavWrapper {...props} classes={classes} theme={theme} /> : <LoginPage {...props} /> }} />
                                            : <Route key='dynRoute' path='/' component={props => { return isSessionActive ? <NDNavWrapper {...props} /> : <LoginPage {...props} /> }} />
                                    }
                                    {/* <Route key='dynRoute' path='/' component={props => { return isSessionActive ? <NDNavWrapper {...props} /> : <LoginPage {...props} /> }} /> */}
                                </Switch>
                            </StyledContainer>
                        </Suspense>
                    </Route>
                </ErrorBoundary>
            </HttpsRedirect>
        );
    }
}

export default withRouter(App);