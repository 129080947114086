import React from 'react';
import { Card } from '@mui/material';

function OMNIInfo() {
    return (
        <Card style={{ overflow: "visible", position: "absolute", marginLeft: 23 + "%", width: 23 + "%", height: 62 + "%", top: 15 + "%", backgroundColor: "#4E2DD2", color: "white" }}>
            <img style={{ marginTop: "0%", height: 20 + "%", display:"block", paddingTop:"6%", marginLeft:"auto",  marginRight:"auto" }} src="https://customerui-profile-icons.s3.amazonaws.com/eHS-SymbolOnly-Black.png" alt="eHS-SymbolOnly-Black.png"></img>
            <div>
                <p style={{ fontWeight: "bold", fontSize: 20, textAlign: "center" }}>Streamline your business</p>
                <p style={{ fontSize: 18, marginLeft: "23%" }}>☑ iPaaS</p>
                <p style={{ fontSize: 18, marginLeft: "23%" }}>☑ Point-to-multipoint</p>
                <p style={{ fontSize: 18, marginLeft: "23%" }}>☑ Agile, scalable & elastic</p>
                <p style={{ fontSize: 18, marginLeft: "23%" }}>☑ Applied & automated business rules</p>
                <p style={{ fontSize: 18, marginLeft: "23%" }}>☑ EU-GDPR Compliant</p>
            </div>
        </Card>
    )
}

export default OMNIInfo;