import React from 'react';
import { withRouter } from 'react-router-dom';
import { secureStorage_removeItem } from '../../utility/secureStorage';
import { removeHubSpot } from '../../utils/hubspot';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    console.error('ErrorBoundary caught an error:', error, errorInfo);
  }

  handleSignOut = () => {
    // Clean up all storage and sessions
    try {
      // Clear all secure storage items
      secureStorage_removeItem('CustomerID');
      secureStorage_removeItem('customername');
      secureStorage_removeItem('workflows');
      secureStorage_removeItem('secondary_customerids');
      secureStorage_removeItem('selected_customername');
      secureStorage_removeItem('access');
      secureStorage_removeItem('database');
      secureStorage_removeItem('accountstatus');

      // Clear localStorage
      localStorage.clear();

      // Clear cookies
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

      // Unload HubSpot if it exists
      removeHubSpot();

      // Force reload to login page
      window.location.href = "/login";
    } catch (error) {
      console.error('Error during sign out:', error);
      // Force reload as fallback
      window.location.reload();
    }
  }

  handleRetry = () => {
    try {
      // Clear error state
      this.setState({
        error: null,
        errorInfo: null,
        hasError: false
      });

      // Reload the current page
      window.location.reload();
    } catch (error) {
      console.error('Error during retry:', error);
      this.handleSignOut(); // Fallback to sign out if retry fails
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          textAlign: 'center',
          padding: '20px'
        }}>
          <img
            style={{
              height: 'auto',
              maxWidth: '300px',
              marginBottom: '30px'
            }}
            src="https://customerui-profile-icons.s3.amazonaws.com/eHS-HorizontalLogo-Black.png"
            alt="eHouse Studio Logo"
          />

          <h2 style={{
            color: '#1B2539',
            marginBottom: '20px'
          }}>Something went wrong</h2>

          <div style={{
            display: 'flex',
            gap: '15px',
            marginTop: '20px'
          }}>
            <button
              onClick={this.handleRetry}
              style={{
                padding: '10px 20px',
                backgroundColor: '#00A9E7',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                fontWeight: '500'
              }}
            >
              Retry
            </button>

            <button
              onClick={this.handleSignOut}
              style={{
                padding: '10px 20px',
                backgroundColor: '#1B2539',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                fontWeight: '500'
              }}
            >
              Sign Out
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);