//Created on July 9th, 2021
//Author: Vaibhavi More
import React from 'react';
//import queryString from 'query-string'
import { isMobile } from "react-device-detect";
import { styled } from '@mui/system';
import { Typography, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import * as config from '../Config/config';
import background from '../../src/loginBackgroundBlueBase.png';
import { secureStorage_getItem, secureStorage_setItem } from '../utility/secureStorage';
import apigClientFactory from '../chalice-javascript-sdk/apigClient';
import spcredsGQ from '../../src/graphql/amazonspcreds';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: '16px',
        textAlign: 'center'
    },
});

class Step2 extends React.Component {

    componentDidMount() {
        console.log('Step2 componentDidMount called');

        try {
            var customerid = secureStorage_getItem("customername")
            var values = new URLSearchParams(this.props.location.search)
            console.log('URLSearchParams values: ', values);
            var accesstoken = localStorage.getItem("accesstoken");
            console.log('Access token: ', accesstoken);

            //gets spapi_oauth_code, state , selling partner id
            fetchCustomerID = async (sellerid) => {
                console.log('fetching customerid for sellerid: ', sellerid);
                fetch(`https://f4q7s67aunnfm7zv3bepcutd7m0rmhcd.lambda-url.us-east-1.on.aws/sellerid/${sellerid}`).then((response) => response.json()).then((responseData) => {
                    console.log('Fetched CustomerID: ', responseData.data[0].CustomerID);
                    secureStorage_setItem('customeridfetched', responseData.data[0].CustomerID);
                }).catch((error) => {
                    console.error('Error fetching customerid: ', error);
                    secureStorage_setItem('customeridfetched', '')
                });
            }

            //amazon_callback_uri, amazon_state, selling_partner_id
            if (values.get('amazon_callback_uri')) secureStorage_setItem("amazon_callback_uri", values.get('amazon_callback_uri'))
            if (values.get('amazon_state')) secureStorage_setItem("amazon_state", values.get('amazon_state'))
            if (values.get('selling_partner_id')) secureStorage_setItem("selling_partner_id", values.get('selling_partner_id'))
            console.log('selling_partner_id: ' + values.get('selling_partner_id'));

            if (values.get('selling_partner_id')) {
                const seller_id_fetch = values.get('selling_partner_id');
                this.fetchCustomerID(seller_id_fetch);
            }

            if (values.get('amazon_callback_uri') && values.get('amazon_state') && values.get('selling_partner_id')) {
                var tempQueryString = '?redirect_uri=https://20.myvlomni.com/vlomni-amazon-api-authorize&amazon_state=' + secureStorage_getItem("amazon_state") + '&state=' + accesstoken
                console.log('Redirecting to: ', secureStorage_getItem("amazon_callback_uri") + tempQueryString);
                window.location = secureStorage_getItem("amazon_callback_uri") + tempQueryString;
            }

            //validate state value with access token to verify customer
            //Save the values:selling_partner_id, mws_auth_token, and spapi_oauth_code
            if (values.get('mws_auth_token')) secureStorage_setItem("mws_auth_token", values.get('mws_auth_token'))
            if (values.get('spapi_oauth_code')) secureStorage_setItem("spapi_oauth_code", values.get('spapi_oauth_code'))

            if (values.get('spapi_oauth_code')) {
                var auth_code = secureStorage_getItem('spapi_oauth_code')
                var apigClient = apigClientFactory.newClient();
                var body = {
                    customerid: secureStorage_getItem("customername"),
                    info: "sp api oauth code: " + auth_code
                }

                var additionalParams = {
                    headers: {
                        "Authorization": accesstoken
                    }
                }
                apigClient.awsopsGenerateLogPut('', body, additionalParams).then(result => {
                    console.log('Log put result: ', result);
                }).catch(function (error) {
                    console.error('Error in awsopsGenerateLogPut: ', error);
                });

                //exchange an LWA authorization code for an LWA refresh token
                var params = {
                    grant_type: "authorization_code",
                    code: auth_code,
                    redirect_uri: 'https://20.myvlomni.com/vlomni-amazon-api-authorize',
                    client_id: 'amzn1.application-oa2-client.e73f8974b5f449269aa9cdff1fabb8e5',
                    client_secret: 'amzn1.oa2-cs.v1.08d2561d1eddc54720b32d1f14947f7bcd53f9ef74a36bbb59667cb638261aee'
                }
                // Simple POST request with a JSON body using fetch
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(params)
                };

                fetch('https://api.amazon.com/auth/o2/token', requestOptions)
                    .then(async response => {
                        const data = await response.json();
                        console.log('Token exchange response data: ', data);

                        //insert into dyn table - temporary code, can be removed later
                        var region = localStorage.getItem("region") ? localStorage.getItem("region") : 'US'
                        var marketPlaceID = config.marketPlaceID[region]

                        var apigClient = apigClientFactory.newClient()
                        var accesstoken = localStorage.getItem("accesstoken")
                        var body = {
                            "CustomerID": secureStorage_getItem('customername'),
                            "SellerID": secureStorage_getItem('selling_partner_id'),
                            "MarketPlaceID": marketPlaceID,
                            "AccessToken": data['access_token'],
                            "RefreshToken": data['refresh_token']
                        };
                        var additionalParams = {
                            headers: {
                                "Authorization": accesstoken
                            }
                        };
                        console.log('body.SellerID.length : ' + body.SellerID.length);
                        console.log('customeridfetched : ' + secureStorage_getItem('customeridfetched'));
                        if (body.SellerID.length == 0 && secureStorage_getItem('customeridfetched')) {
                            body.SellerID = secureStorage_getItem('customeridfetched')
                        };

                        apigClient.dbopsInsertAmazonMWS_CredsPost("", body, additionalParams).then(async (result) => {
                            console.log('dbopsInsertAmazonMWS_CredsPost result: ', result);
                            let values = {
                                customerid: secureStorage_getItem("customername"),
                                info: "sp api credentials received"
                            }

                            apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                                console.log('Log put result: ', result);
                            }).catch(function (error) {
                                console.error('Error in awsopsGenerateLogPut: ', error);
                            });

                        }).catch(function (error) {
                            console.error('Error in dbopsInsertAmazonMWS_CredsPost: ', error);
                            let values = {
                                customerid: secureStorage_getItem("customername"),
                                info: "error while inserting credentials to dyn table " + error
                            }

                            apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                                console.log('Log put result: ', result);
                            }).catch(function (error) {
                                console.error('Error in awsopsGenerateLogPut: ', error);
                            });
                        });

                        var values = {
                            customerid: secureStorage_getItem("customername"),
                            info: "sp api credentials " + JSON.stringify(body)
                        }

                        apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                            console.log('Log put result: ', result);
                        }).catch(function (error) {
                            console.error('Error in awsopsGenerateLogPut: ', error);
                        });

                        //insert into dynamo table - prod us for now
                        var filters = `{ CustomerID: {eq: ${secureStorage_getItem("CustomerID")}}, MarketPlaceId: {eq: "${marketPlaceID}"}, SellerID: {eq: "${secureStorage_getItem('selling_partner_id')}"}}`

                        await spcredsGQ.listAmazonSPCreds(filters, "CustomerID").then(result => {
                            console.log('listAmazonSPCreds result: ', result);
                            let params = {
                                "CustomerID": secureStorage_getItem('CustomerID'),
                                "SellerID": secureStorage_getItem('selling_partner_id'),
                                "MarketPlaceID": marketPlaceID,
                                "AccessToken": data['access_token'],
                                "RefreshToken": data['refresh_token']
                            }
                            if (params.CustomerID.length == 0) {
                                params.CustomerID = secureStorage_getItem('customeridfetched')
                            }
                            if (!result.length) {
                                spcredsGQ.createAmazonSPCreds(params).then(result => {
                                    console.log('createAmazonSPCreds result: ', result);
                                    var values = {
                                        customerid: secureStorage_getItem('customername'),
                                        info: "insert sp api credentials to dynamo:" + JSON.stringify(result)
                                    }
                                    apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                                        console.log('Log put result: ', result);
                                    }).catch(function (error) {
                                        console.error('Error in awsopsGenerateLogPut: ', error);
                                    });
                                }).catch(function (error) {
                                    console.error('Error in createAmazonSPCreds: ', error);
                                    var values = {
                                        customerid: secureStorage_getItem("customername"),
                                        info: "error while inserting amazon credentials: " + JSON.stringify(error)
                                    }

                                    apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                                        console.log('Log put result: ', result);
                                    }).catch(function (error) {
                                        console.error('Error in awsopsGenerateLogPut: ', error);
                                    });
                                })
                            } else {
                                spcredsGQ.updateAmazonSPCreds(params).then(result => {
                                    console.log('updateAmazonSPCreds result: ', result);
                                    var values = {
                                        customerid: secureStorage_getItem('customername'),
                                        info: "update sp api credentials to dynamo " + JSON.stringify(result)
                                    }
                                    apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                                        console.log('Log put result: ', result);
                                    }).catch(function (error) {
                                        console.error('Error in awsopsGenerateLogPut: ', error);
                                    });
                                }).catch(function (error) {
                                    console.error('Error in updateAmazonSPCreds: ', error);
                                    var values = {
                                        customerid: secureStorage_getItem("customername"),
                                        info: "error while updating customer credentials: " + JSON.stringify(error)
                                    }

                                    apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                                        console.log('Log put result: ', result);
                                    }).catch(function (error) {
                                        console.error('Error in awsopsGenerateLogPut: ', error);
                                    });
                                })
                            }
                            return result
                        }).catch(error => {
                            console.error('Error in listAmazonSPCreds: ', error);
                            var values = {
                                customerid: secureStorage_getItem("customername"),
                                info: "error while getting amazon credentials " + JSON.stringify(error)
                            }

                            apigClient.awsopsGenerateLogPut('', values, additionalParams).then(result => {
                                console.log('Log put result: ', result);
                            }).catch(function (error) {
                                console.error('Error in awsopsGenerateLogPut: ', error);
                            });
                        })

                    })
                    .catch(error => {
                        console.error('Error in token exchange: ', error);
                        let body = {
                            customerid: secureStorage_getItem("customername"),
                            info: "error while fetching token " + error
                        }

                        apigClient.awsopsGenerateLogPut('', body, additionalParams).then(result => {
                            console.log('Log put result: ', result);
                        }).catch(function (error) {
                            console.error('Error in awsopsGenerateLogPut: ', error);
                        });
                    })
            } else {
                console.log("auth code not generated");
                var apigClient = apigClientFactory.newClient();

                var additionalParams = {
                    headers: {
                        "Authorization": accesstoken
                    }
                }
                let body = {
                    customerid: secureStorage_getItem("customername"),
                    info: "auth code not generated"
                }

                apigClient.awsopsGenerateLogPut('', body, additionalParams).then(result => {
                    console.log('Log put result: ', result);
                }).catch(function (error) {
                    console.error('Error in awsopsGenerateLogPut: ', error);
                });
            }
        }
        catch (error) {
            console.error('Error in componentDidMount: ', error);
        }

    }

    render() {
        document.title = "eHouse Studio";

        if (isMobile) {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        <div style={{ position: "absolute", top: 40 + "%", left: 50 + '%', verticalAlign: "middle", transform: "translate(-50%, -50%)", textAlign: "center" }}>
                            <img src={config.defaultVLLogo} style={{ maxWidth: '50px', maxHeight: '100%', marginRight: "auto" }} alt="eHouse Studio" />
                            <Typography variant="h4" style={{ color: "#1db3e9", marginTop: 30 + '%', fontFamily: "Lato", fontSize: 45 + "px", fontWeight: "bold", verticalAlign: "middle" }}>THANK YOU</Typography>
                            <Typography style={{ color: "#6d6e70", margin: 30 + 'px', fontWeight: "bold", fontSize: 25 + "px", marginRight: "auto" }}>eHouse Studio has received your information.</Typography>
                            <Link to="/" style={{ backgroundColor: "#4E2DD2", color: "white", fontWeight: "bold", fontFamily: "Lato", padding: "1em 1.5em", textDecoration: "none" }}>Take me back to Dashboard</Link>
                        </div>
                        <img style={{ width: 100 + "%", height: 100 + "%", backgroundColor: "#00a9e7" }} src={background} alt="Background"></img>
                    </Paper>
                </div>
            )
        } else {
            return (
                <div style={{ fontFamily: "Lato" }}>
                    <Paper style={{ backgroundColor: "rgb(27, 37, 57)", borderRadius: 0, position: "fixed", width: 100 + "%", height: 100 + "%" }}>
                        <div style={{ position: "absolute", top: 40 + "%", left: 50 + '%', verticalAlign: "middle", transform: "translate(-50%, -50%)", textAlign: "center" }}>
                            <img src={config.defaultVLLogo} style={{ maxWidth: '63px', maxHeight: '100%', marginRight: "auto" }} alt="eHouse Studio" />
                            <Typography variant="h4" style={{ color: "#1db3e9", marginTop: 30 + '%', fontFamily: "Lato", fontSize: 45 + "px", fontWeight: "bold", verticalAlign: "middle" }}>THANK YOU</Typography>
                            <Typography style={{ color: "#6d6e70", margin: 30 + 'px', fontWeight: "bold", fontSize: 25 + "px", marginRight: "auto" }}>eHouse Studio has received your information.</Typography>
                            <Link to="/" style={{ backgroundColor: "#4E2DD2", color: "white", fontWeight: "bold", padding: "1em 1.5em", textDecoration: "none" }}>Take me back to Dashboard</Link>
                        </div>
                        <img style={{ width: 100 + "%", backgroundColor: "#00a9e7" }} src={background} alt="Background"></img>
                    </Paper>
                </div>
            )
        }
    }
}


export default styled(Step2)(styles);